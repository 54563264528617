import { Box, Button, Input, Stack, Typography } from "@mui/joy";

import MainTemplate from "../../../templates/main";
import { useCallback, useEffect, useRef, useState } from "react";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import "./_verification.scss";
import { Link, useParams } from "react-router-dom";

export default function RemoteVerificationPage({ sender }: { sender: boolean }) {
  const { invoice } = useParams()

  const [sid, setSid] = useState(null);
  const [pin, setPin] = useState("");
  const [success, setSuccess] = useState(false);
  const [coords, setCoords] = useState<any>({});
 
  const pinInput1 = useRef(null);
  const pinInput2 = useRef(null);
  const pinInput3 = useRef(null);
  const pinInput4 = useRef(null);
  const pinInput5 = useRef(null);
  const pinInput6 = useRef(null);

  useEffect(() => {
    if (pinInput1.current) {
      (pinInput1.current as any).focus();
    }

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setCoords(position.coords)
      });
    }

  }, []);

  useEffect(() => {
    fetch(`https://hook.us1.make.com/2ybdigmt8fxejrrnfvjq0m35b9qgx66g?inv=${invoice}`)
      .then(result => result.json())
      .then((data: any) => {
        if (data.sid) setSid(data.sid)
        else if (data.success) setSuccess(data.success)
      })
  }, [invoice]);

  const keyUpToNext = (ref: any) => (e: any) => {
    if (0 <= Number(e.key) && Number(e.key) <= 9) {
      ref.current.focus()
      const p = `${(pinInput1.current as any).value}${(pinInput2.current as any).value}${(pinInput3.current as any).value}${(pinInput4.current as any).value}${(pinInput5.current as any).value}${(pinInput6.current as any).value}`
      setPin(p)
    }
  }

  const autofillPin = useCallback((e: any) => {
    if (e.target.value.length === 6) {
      setPin(e.target.value)
      const p: any[] = e.target.value.split("");
      (pinInput1.current as any).value = p[0];
      (pinInput2.current as any).value = p[1];
      (pinInput3.current as any).value = p[2];
      (pinInput4.current as any).value = p[3];
      (pinInput5.current as any).value = p[4];
      (pinInput6.current as any).value = p[5];
    }
  }, []);

  const submitForm = useCallback(() => {

    fetch(`https://hook.us1.make.com/qsm25iqpsakudffip023kfo666u2jsma`, {
      method: 'POST',
      body: JSON.stringify({
        remote: true,
        code: pin,
        sid,
        lat: coords.latitude,
        long: coords.longitude
      } as any),
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(results => results.json()).then(data => {
      setSuccess(data.success)
    })

  }, [sid, pin, coords]);


  return (
    <MainTemplate page="Verify" sender={sender}>
      <>
        <Box
          sx={{
            display: 'flex',
            mb: 1,
            gap: 1,
            flexDirection: { xs: 'column', sm: 'row' },
            alignItems: { xs: 'start', sm: 'center' },
            flexWrap: 'wrap',
            justifyContent: 'space-between',
          }}
        >
          <Typography level="h2" component="h1">
            Confirm Package Received
          </Typography>
        </Box>
        {!success ? <Stack height="400px" justifyContent="center" spacing={8} padding="18px">
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Input onChange={autofillPin} slotProps={{ input: { ref: pinInput1, pattern: "[0-9]*", className: "otp-verification" }}} onKeyUp={keyUpToNext(pinInput2)} autoComplete="one-time-code" variant="outlined" />
            <Input onChange={autofillPin} slotProps={{ input: { maxLength: 1, ref: pinInput2, pattern: "[0-9]*", className: "otp-verification" }}} onKeyUp={keyUpToNext(pinInput3)} variant="outlined" />
            <Input onChange={autofillPin} slotProps={{ input: { maxLength: 1, ref: pinInput3, pattern: "[0-9]*", className: "otp-verification" }}} onKeyUp={keyUpToNext(pinInput4)} variant="outlined" />
            <Input onChange={autofillPin} slotProps={{ input: { maxLength: 1, ref: pinInput4, pattern: "[0-9]*", className: "otp-verification" }}} onKeyUp={keyUpToNext(pinInput5)} variant="outlined" />
            <Input onChange={autofillPin} slotProps={{ input: { maxLength: 1, ref: pinInput5, pattern: "[0-9]*", className: "otp-verification" }}} onKeyUp={keyUpToNext(pinInput6)} variant="outlined" />
            <Input onChange={autofillPin} slotProps={{ input: { maxLength: 1, ref: pinInput6, pattern: "[0-9]*", className: "otp-verification" }}} onKeyUp={keyUpToNext(pinInput6)} variant="outlined" />
          </Stack>
          <Button disabled={pin.length !== 6} onClick={submitForm}>Verify</Button>
        </Stack> :
        <Stack height="60%" justifyContent="center" alignItems="center" spacing={2} padding="18px">
          <CheckCircleIcon color="success" style={{ width: 200, height: 200 }} />
          <Typography textAlign="center" level="h2" color="success">Success! Your delivery has been confirmed!</Typography>
          <Typography textAlign="center" level="h4">You can close this window</Typography>
          <Link to={`/recipient/order-status/${invoice}`}>Back to Order Status</Link>
        </Stack>
        }
      </>
    </MainTemplate>
  );
}